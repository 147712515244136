<template>
    <div>
         <vue-dropzone
            :ref="'myVueDropzone' + i"
            :id="'dropzone' + i"
            :options="dropzoneOptions"
            v-model="model"
            v-on:vdropzone-sending="sendFile"
            v-on:vdropzone-success="addUpload"
            v-on:vdropzone-removed-file="removeUpload"
        ></vue-dropzone>
        <div class="v-messages v-messages--top-border pl-2 pb-2">
            <div class="v-messages__wrapper">
                <div class="v-messages__message">
                    <span v-text="hint" />
                </div>
            </div>
        </div>
        <v-list v-if="model.length">
            <v-list-item
                v-for="file in model"
                :key="file[itemKey]"
                @click="$getFile(`/api/enclosures/download/${file[itemKey]}`, file[itemText], true)"
                :disabled="$wait.is('fetching file')"
            >
                <v-list-item-action>
                    <v-icon color="primary"
                        >cloud_download</v-icon
                    >
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title
                        v-text="file[itemText]"
                    ></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-tooltip left color="error">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                @click="deleteItem(file)"
                                color="error"
                                icon
                                :disabled="disabled"
                            >
                            <v-icon
                                color="error"
                                >mdi-close</v-icon
                            >
                            </v-btn>
                        </template>
                        <span v-t="'remove'" />
                    </v-tooltip>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </div>
</template>


<script>
import vueDropzone from 'vue2-dropzone';
import { getToken } from '@/utils/auth';

export default {
    name: 'Upload',
    props: {
        formScope: {
            type: String,
            default: null,
        },
        apiUploadRoute: {
            type: String,
            default: '/api/enclosures/upload',
        },
        value: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        i: {
            type: Number,
            default: null,
        },
        label: {
            type: String,
            default: null
        },
        hint: {
            type: String,
            default: null
        },
        itemText: {
            type: String,
            default: 'filename'
        },
        itemKey: {
            type: String,
            default: 'uuid'
        },
        enclosureType: {
            type: String,
            default: 'enclosure'
        }
    },
    components: {
        vueDropzone,
    },
    data () {
        return {
            dropzoneOptions: {
                headers: null,
                dictDefaultMessage: null,
                url: null,
                addRemoveLinks: true,
                thumbnailHeight: 120,
                maxFilesize: 20,
                createImageThumbnails: false,
                acceptedFiles: 'image/*,application/pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptm,.pptx',
            }
        }
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit(val);
            }
        }
    },
    methods: {
        deleteItem(file) {
            this.model.splice(this.model.indexOf(file), 1);
        },
        sendFile(file, xhr, formData) {
            formData.append('clientId', file.upload.uuid);
            formData.append('type', this.enclosureType.toUpperCase());
        },
        addUpload(file, response) {
            this.model.unshift({ ...file.upload, ...response });
            this.$emit('modified');
            this.$refs['myVueDropzone' + this.i].removeFile(file);
        },
        removeUpload() {
            this.$emit('modified');
        },
    },
    created () {
        
        const uploadHeaders = {
            'Cache-Control': null,
            'X-Requested-With': null,
            Accept: `application/json, application/prs.lea+json;v=${process.env.VUE_APP_VERSION}`
        }

        if (process.env.VUE_APP_SSO !== 'true') {
            uploadHeaders.Authorization = `Bearer ${getToken()}`
        }

        this.dropzoneOptions.headers = uploadHeaders;
        this.dropzoneOptions.dictDefaultMessage = this.label;
        this.dropzoneOptions.url = `${process.env.VUE_APP_BASE_URL}` + this.apiUploadRoute;
    },
    mounted () {
        if (this.disabled) {
            this.$refs['myVueDropzone' + this.i].disable();
        }
    },
}

</script>